<template>
  <v-card>
    <v-toolbar elevation="1" flat>
      <v-toolbar-title>
        <span class="headline">Novo pacote de mídias</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="editing ? handleClose() : goBack()">mdi-arrow-left</v-icon>
    </v-toolbar>
    <v-divider />
    <v-col cols="12" class="d-flex flex-wrap">
      <v-col v-if="editing" cols="4">
        <v-select v-model="situacaoPacote" :items="situacoes" disabled label="Situação"></v-select>
      </v-col>
      <v-col v-if="editing && pacoteMidia.dataLiberacao" cols="4">
        <div class="d-flex flex-wrap">
          <v-menu ref="menu" v-model="menu" disabled :close-on-content-click="false" :return-value.sync="dataInicio"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="dataLiberacao" label="Data" disabled prepend-icon="mdi-calendar" readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dataInicio" no-title disabled locale="pt-BR" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dataInicio)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-row class="w-full px-6">
        <v-text-field v-model="descricao" :disabled="editing && (isLiberado || isBloqueado || isCancelado)"
          label="Descrição" />
      </v-row>
      <v-row class="w-full px-6 mt-2">
        <v-textarea v-model="observacao" :disabled="editing && (isLiberado || isBloqueado || isCancelado)"
          label="Observação" />
      </v-row>
    </v-col>

    <v-col cols="12" id="action" class="w-full pr-6 d-flex  flex-row-reverse">
      <v-btn color="primary" :disabled="editing && (isLiberado || isBloqueado || isCancelado)" class="ml-2"
        @click="salvarPacote(1)">
        Salvar
      </v-btn>
      <v-btn v-if="editing" :disabled="editing && (isLiberado || isBloqueado || isCancelado)" color="primary"
        class="mx-2" @click="handleAction(2)">
        Liberar
      </v-btn>
      <v-btn v-if="editing" :disabled="isCancelado || isHabilitado" color="primary" class="mx-2"
        @click="handleAction(3)">
        Bloquear
      </v-btn>
      <v-btn v-if="editing" :disabled="!isHabilitado" color="primary" @click="handleAction(4)" class="mx-2">
        Cancelar
      </v-btn>
    </v-col>
    <v-dialog v-model="confirmacaoBloquear" persistent max-width="460">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Realmente Prosseguir?
        </v-card-title>
        <v-card-text>
          O pacote não estará mais disponível para edição. A ação é
          irreversível.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacaoBloquear = false">
            Voltar
          </v-btn>
          <v-btn color="error" text @click="salvarPacote(typeOperation)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import PacoteMidia from "@/services/PacoteMidia";
import { ProdutoService } from "@/services";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },

    pacoteMidia: {
      type: [Object, Array],
      default: () => { },
    },
  },

  data() {
    return {
      situacaoPacote: null,
      dataInicio: null,
      confirmacaoBloquear: false,
      menu: false,
      descricao: null,
      typeOperation: 0,
      situacoes: [],
      observacao: null,
    };
  },

  mounted() {
    if (this.pacoteMidia) {
      this.descricao = this.pacoteMidia.descricao;
      this.situacaoPacote = this.pacoteMidia.situacaoPacote;
      this.observacao = this.pacoteMidia.observacao;
      this.dataInicio = moment(this.pacoteMidia.dataLiberacao).format(
        "Y-MM-DD"
      );
    }
  },

  created() {
    this.getSituacao();
  },

  computed: {
    dataLiberacao() {
      return this.formatDate(this.dataInicio);
    },

    isHabilitado() {
      return this.situacaoPacote == 1 ? true : false;
    },

    isLiberado() {
      return this.situacaoPacote == 2 ? true : false;
    },

    isPublicado() {
      return this.situacaoPacote == 5 ? true : false;
    },

    isBloqueado() {
      return this.situacaoPacote == 3 ? true : false;
    },

    isCancelado() {
      return this.situacaoPacote == 4 ? true : false;
    },
  },

  methods: {
    goBack() {
      this.$router.push("/pacote-midias");
    },

    handleClose() {
      this.$emit("close");
    },

    handleAction(number) {
      this.typeOperation = number
      this.confirmacaoBloquear = true
    },

    getSituacao() {
      ProdutoService.getSituacoes()
        .then((response) => {
          this.situacoes = response.data.map((s) => ({
            text: s.descricao,
            value: s.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    salvarPacote(type) {
      if (this.editing && type == 2) {
        if (!this.pacoteMidia.midiaCapa) return this.$root.showErro("É necessário uma capa para a liberação");
      }
      if (!this.descricao || this.descricao == "")
        return this.$root.showErro("Insira uma Descrição");

      if (!this.observacao || this.observacao == "")
        return this.$root.showErro("Insira uma Observação");

      if (type == 2) {
        this.dataLiberacao = new Date();
      }
      let payload = {
        descricao: this.descricao,
        ...(this.pacoteMidia && { id: this.pacoteMidia.id }),
        situacaoPacote: type,
        observacao: this.observacao,
        ...(this.dataLiberacao && { dataLiberacao: new Date() }),
      };
      PacoteMidia.cadastrarPacote(payload)
        .then(() => {
          this.$root.showSucesso("Sucesso");
          this.$emit("reload");
          this.situacaoPacote = type;
          this.handleClose();
          if (window.location.pathname == "/cadastro-midias")
            this.$router.push("/pacote-midias");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.w-full {
  width: 100%;
}
</style>
